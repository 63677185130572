import React, { useEffect, useState, useContext, useMemo } from "react";
import {
  BasicEditTextView,
  SelectInput,
  SelectCurrencyInput,
  SaveCancelButton,
  SelectAstroInput
} from "../../Components/Edit Profile/EditProfileUtills";
import { useFormik } from "formik";
import { LoginContext } from "../../Contexts/LoginContext";
import * as Yup from "yup";
import EndPoints from "../../Constants/EndPoints";
import { POST } from "../../Services/api_services";
import { validateNumber } from "../../Utils";
import Constants, { EventName } from "../../Constants/constants";
import config from "../../config";
import { useAnalytics } from "../../Hooks/usePageChange";
import { useDispatch, useSelector } from "react-redux";
import { registerDetail } from "../../Store/Home/Action";
import { NavBarContext } from "../../Contexts/NavBarContext";
import axios from "axios";
import LoaderGif from "../../Gif/loader.gif"

const FamilyAddComponent = (props) => {
  const { loginDetail, logout } = useContext(LoginContext);
  const [isShowEdit, setEditView] = useState(false);
  const [familyStatusDropDown, setfamilyStatusDropDown] = useState([]);
  const [familyTypeDropDown, setfamilyTypeDropDown] = useState([]);
  const [familyValuesDropDown, setfamilyValuesDropDown] = useState([]);
  const [parentsIsdCodeDropDown, setparentsIsdCodeDropDown] = useState([]);
  const [currencyDropDown, setcurrencyDropDown] = useState([]);
  // const [submitStatus, setSubmitStatus] = useState(false);
  const [isParentNumberChanged, setIsParentNumberChanged] = useState(false);
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [familyRelationDropDown, setfamilyRelationDropDown] = useState([]);
  const [relationDropDown, setRelationDropDown] = useState([{ id: 5, name: "Mother" }, { id: 6, name: "Father" }]);
  const dispatch = useDispatch();
  const [onchangefamilydata, setOnchangeFamilyData] = useState(true)
  console.log(onchangefamilydata, "onchangefamilydata");
  const { completeNow, setCompleteNow, clickAddHabits, setClickAddHabits,
    clickAddFamily, setClickAddFamily, clickAddAstro, setClickAddAstro,
    clickAddHoro, setClickAddHoro, submitStatus, setSubmitStatus } = useContext(NavBarContext);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [commonError, setCommonError] = useState("");

  const [packStatusData, setPackStatusData] = useState(null)
  const [changedFieldsCount, setChangedFieldsCount] = useState(0);


  console.log(packStatusData, "packStatusDatapackStatusData");
  useEffect(() => {
    dispatch(registerDetail());
  }, []);

  const { dropdownData } = useSelector((state) => ({
    dropdownData: state?.Home?.rData?.data,
  }));

  const userData = useSelector(
    (state) => state?.DashBoardAPI?.ProfileData?.data
  );
  
  const relationship = dropdownData?.relationShip[0]?.name

  console.log(clickAddFamily, 'clickAddFamily');
  const formik = useFormik({
    initialValues: {
      familyStatus: props?.data?.familyStatus ? props?.data?.familyStatus[0]?.id : "",
      familyType: props?.data?.familyType ? props?.data?.familyType[0]?.id : "",
      familyValues: props?.data?.familyValues ? props?.data?.familyValues[0]?.id : "",
      parentsIsdCode: props?.data?.parentsIsdCode || "",
      parentNumber: props?.data?.parentsMobileNumber || "",
      parentsMonthly: props?.data?.parentsMonthly || "",
      currency: props?.data?.parentsCurrency?.currency || "",
      relationshipId: props?.data?.relationshipId
        ? props?.data?.relationshipId
        : "",
      descriptionEdit: props?.data?.descriptionEdit
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      familyStatus: Yup.string(),
      familyType: Yup.string(),
      familyValues: Yup.string(),
      parentsIsdCode: Yup.string(),
      relationshipId: Yup.lazy((value, options) =>
        value || options.parent.parentNumber
          ? Yup.string().required('Please select a relationship ')
          : Yup.string()
      ),
      parentNumber: Yup.lazy((value, options) =>
        value || options.parent.relationshipId
          ? Yup.string()
            .test('valid-number', 'Please enter a valid Mobile Number', function (value) {
              const { parentsIsdCode } = this.parent;
              if (parentsIsdCode === '+91') {
                return value.length === 10 || this.createError({ message: 'Please enter a valid Mobile Number', path: 'parentNumber' });
              } else {
                return (value.length >= 7 && value.length <= 15) || this.createError({ message: 'Please enter a valid Mobile Number', path: 'parentNumber' });
              }
            })
            .required('Please enter Mobile Number')
          : Yup.string()
      ),
      parentsMonthly: Yup.string(),
    }),

    onSubmit: (values) => {
      setSubmitStatus(true);
      apiCallFamily(values);


    },
  });




  console.log(props?.data?.parentsIsdCode, "props?.data?.parentsIsdCode");
  useEffect(() => {
    setfamilyStatusDropDown(props?.dropDown?.familyStatusData || []);
    setfamilyTypeDropDown(props?.dropDown?.familyTypeData || []);
    setfamilyValuesDropDown(props?.dropDown?.familyValuesData || []);
    setparentsIsdCodeDropDown(props?.dropDown?.isdcode || []);
    setcurrencyDropDown(props?.dropDown?.currencyData || []);
    setfamilyRelationDropDown(dropdownData?.relationShip || [])
  }, [props?.dropDown]);


  useEffect(() => { }, [
    familyStatusDropDown,
    familyTypeDropDown,
    familyValuesDropDown,
    parentsIsdCodeDropDown,
    currencyDropDown,
    relationDropDown
  ]);
  const NotificationId = localStorage.getItem(Constants.loginLocalStorageKeys.trailPack) == 'Y' ? sessionStorage.getItem(Constants.loginLocalStorageKeys.notificationId) : userData?.popup[0]?.notificationId
  const apiCallFamily = async (values) => {
    const request = {
      email: loginDetail()[0],
      userId: loginDetail()[1],
      loginFrom: localStorage.getItem(Constants.loginLocalStorageKeys.loginDevice),
      FamilyStatusId: values.familyStatus,
      familyTypeId: values.familyType,
      familyValuesId: values.familyValues,
      parentsMobileNumber: isParentNumberChanged ? values.parentNumber : "",
      parentsIsdcode: values.parentsIsdCode,
      parentsMonthly: values.parentsMonthly,
      parentsCurrency: values.currency === selectedParentsCurrenyCode?.name ? selectedParentsCurrenyCode.id : values.currency,
      relationshipId : values.relationshipId,
      ...(props.isFrom === 'TrailPack' && { editviatrialpack: props.editviatrialpack }),
      ...(props.isFrom === 'TrailPack' && { notificationId: NotificationId }),
      descriptionEdit: values.descriptionEdit
    };

    const { statusCode, data } = await POST(
      `${config.api.API_URL}${EndPoints.editfamilyinfosaveUrl()}`,
      request
    );

    if (statusCode === 200) {
      
      if (data.data.status === "SUCCESS") {
        if (!formik.values.familyStatus || !formik.values.familyType || !formik.values.familyValues
          || !formik.values.parentsIsdCode
          || !formik.values.parentNumber || !formik.values.parentsMonthly || !formik.values.relationshipId
        ) {
          setCommonError("All fields are mandatory");
        } else {
          setCommonError("");
          setTimeout(() => {
            setClickAddFamily(false);
            props.setCompleteNow(true);
            props?.postData();
          }, 2000);
        }
        setSubmitStatus(false);
        setIsParentNumberChanged(false);
        props?.callBackReload();
        if(props.isFrom !== 'TrailPack'){
          setClickAddFamily(false)
        }
        // setClickAddFamily(false)
        setEditView(false);
        onClickTrack(EventName.profileFamilySaveButton, { [EventName.profileFamilySaveButton]: values });


      } else {
        setSubmitStatus(false);
        setPhoneNumberError(data?.data?.statusMessage);
      }
    } else if (statusCode === 401) {
      logout();
    }
  };

  useEffect(()=>{
    props.apiCallEditProfile()
  },[])

  const selectedFamilyStatus = useMemo(() => {
    return familyStatusDropDown?.find(option => option.id === formik.values.familyStatus) || null;
  }, [familyStatusDropDown, formik.values.familyStatus]);

  const selectedFamilyType = useMemo(() => {
    return familyTypeDropDown?.find(option => option.id === formik.values.familyType) || null;
  }, [familyTypeDropDown, formik.values.familyType]);

  const selectedFamilyValues = useMemo(() => {
    return familyValuesDropDown?.find(option => option.id === formik.values.familyValues) || null;
  }, [familyValuesDropDown, formik.values.familyValues]);

  const selectedParentsIsdCode = useMemo(() => {
    return parentsIsdCodeDropDown?.find(option => option.isdcode === formik.values.parentsIsdCode) || null;
  }, [formik.values.parentsIsdCode, parentsIsdCodeDropDown]);

  const selectedParentsCurrenyCode = useMemo(() => {
    return currencyDropDown?.find(option => option.name === formik.values.currency) || null;
  }, [formik.values.currency, currencyDropDown]);


  const selectedRelation = useMemo(() => {
    return relationDropDown?.filter(option => option.id == formik.values.relationshipId)?.at(0)
  }, [relationDropDown, formik.values.relationshipId])

  const { onClickTrack } = useAnalytics();

  const saveButtonStyle = formik.dirty ? 'bg-red-500' : 'bg-gray-500';

  const countFilledFields = () => {
    const values = formik.values;
    let count = 0;
  
    for (const key in values) {
      // Skip counting the specified fields
      if (values[key] && key !== "parentsIsdCode" && key !== "currency" && key !== "descriptionEdit") {
        count++;
      }
    }   
  
    return count;
  };
  
  const handleFieldChange = (field, value) => {
    const initialValue = formik.initialValues[field];
    const currentValue = formik.values[field];
  
    // If the current value is different from the initial value and the new value is also different
    if (currentValue !== initialValue && value === initialValue) {
      setChangedFieldsCount((prev) => prev - 1); // Decrease count if field is reverted
    } else if (currentValue === initialValue && value !== initialValue) {
      setChangedFieldsCount((prev) => prev + 1); // Increase count if field is modified
    }
  
    formik.setFieldValue(field, value); // Set the field value in formik
  };
  


  return (
    <div>

      <form onSubmit={formik.handleSubmit}>
        <div className="grid md:grid-cols-2">
          <SelectAstroInput
            name="familyStatus"
            title="Family Status"
            value={selectedFamilyStatus}
            onChange={(selected) => {
              handleFieldChange("familyStatus", selected.id)
              setOnchangeFamilyData(false)
            }
            }
            error={formik.errors.familyStatus}
            defaultValue={formik.values.familyStatus}
            options={[{ id: "", name: "Select", isDisabled: true }, ...familyStatusDropDown]}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            menu="packastro"
          />
          <SelectAstroInput
            name="familyType"
            title="Family Type"
            value={selectedFamilyType}
            onChange={(selected) => {
              handleFieldChange("familyType", selected.id)
              setOnchangeFamilyData(false)
            }}
            error={formik.errors.familyType}
            defaultValue={formik.values.familyType}
            options={[{ id: "", name: "Select", isDisabled: true }, ...familyTypeDropDown]}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            menu="packastro"
          />
          <SelectAstroInput
            name="familyValues"
            title="Family Values"
            value={selectedFamilyValues}
            onChange={(selected) => {
              handleFieldChange("familyValues", selected.id)
              setOnchangeFamilyData(false)
            }}
            error={formik.errors.familyValues}
            defaultValue={formik.values.familyValues}
            options={[{ id: "", name: "Select", isDisabled: true }, ...familyValuesDropDown]}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            menu="packastro"
          />
          <SelectAstroInput
            name="parentsIsdCode"
            title="Parent's ISD Code"
            value={selectedParentsIsdCode}
            onChange={(selected) => {
              handleFieldChange("parentsIsdCode", selected.isdcode)
              setOnchangeFamilyData(false)

            }}
            error={formik.errors.parentsIsdCode}
            defaultValue={formik.values.parentsIsdCode}
            options={[{ id: "", name: "Select", isDisabled: true }, ...parentsIsdCodeDropDown]}
            getOptionLabel={(option) => `${option.name ? option.name : option.isdcode + " - " + option.country}`}
            getOptionValue={(option) => option.isdcode}
            menu="packastro"
          />
          <BasicEditTextView
            title="Parent's Mobile Number"
            inputID="parentNumber"
            inputName="parentNumber"
            value={formik.values.parentNumber}
            onChange={(e) => {
              if (validateNumber(e.target.value)) {
                setPhoneNumberError("");
                setIsParentNumberChanged(true);
                handleFieldChange("parentNumber", e.target.value);
                setOnchangeFamilyData(false)

              }
            }}
            // onKeyDown={blockInvalidCharForNumber}
            error={formik.errors.parentNumber || phoneNumberError}
            maxLength={formik.values.parentsIsdCode === "+91" ? 10 : 15}
            menu="addfamily"
            required={true}
          />
          <SelectAstroInput
            name="relationshipId"
            title="Parents Relation"
            value={selectedRelation}
            onChange={(e) => {
              handleFieldChange("relationshipId", e.id)
              setOnchangeFamilyData(false)

            }}
            error={formik.errors.relationshipId}
            defaultValue={formik.values.relationshipId}
            options={[{ id: "", name: "Select", isDisabled: true }, ...relationDropDown]}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            menu="packastro"
          />

          <div className="  mt-[2rem] ">
            <div className="items-center  md:pl-[1rem] lg:pl-[1rem] ">
              <p className=" md:text-[13px] lg:text-[14px] flex text-[#000000] font-bold  ">
                Parent's Monthly Income {<span className="text-[#D10A11] ml-1"> *</span>}
                {/* <span className="text-[#D10A11]">*</span> */}
              </p>
            </div>
            <div className=" md:pr-[1rem] lg:pr-[1rem]  md:pl-[1rem]">
              <p className="  ">
                <div className="flex space-x-3 ">
                  {/* <label className=" select font-bold text-[#575556] w-[8rem]"> */}
                  <SelectCurrencyInput

                    menu="addfamily"
                    name="currency"
                    id="currency"
                    title="currency"
                    error={formik.errors.currency}
                    defaultValue={formik.values.currency}
                    value={selectedParentsCurrenyCode}
                    // onChange={formik.handleChange}
                    onChange={(selected, ev) => {
                      handleFieldChange("currency", selected.name)
                      setOnchangeFamilyData(false)

                    }}
                    className="  w-full md:p-[0.4rem] lg:p-2.5  border border-[#8F8F8F] text-[#575556] font-semibold md:text-[12px] lg:text-[14px] bg-white rounded-[8px]"


                    options={[{ id: "", name: "Select", isDisabled: true }, ...currencyDropDown]}
                    getOptionLabel={(option) => `${option.name ? option.name : option.currency}`}
                    getOptionValue={(option) => option.id}


                  />

                  <p className=" flex items-center w-[100%] pt-[1px] RegPhone">
                    <input
                      inputID={"parentsMonthly"}
                      inputName={"parentsMonthly"}
                      type="tel"
                      onChange={(e) => {
                        if (validateNumber(e.target.value)) {
                          formik.handleChange(e)


                        }
                      }}
                      id="parentsMonthly"
                      value={formik.values.parentsMonthly}
                      className=" RegPhone  w-[100%] border border-[#8F8F8F] rounded-[3px] h-[35px] p-[0.4rem] block  md:p-[0.4rem] lg:p-2.5 text-[#575556] font-semibold md:text-[12px] lg:text-[12px]"
                    />
                  </p>

                  { }
                </div>
                {formik.errors.parentsMonthly && (
                  <p className="text-[red] text-[12px] font-bold">
                    {formik.errors.parentsMonthly}
                  </p>
                )}
              </p>
            </div>
          </div>
        </div>
        {commonError && (
            <div className="text-red-500 font-bold text-[13px] text-center mt-4">
              {props.menu == 'addfamily' ? <>*{commonError}</> : commonError }
            </div>
          )}
          { props?.isFrom == 'TrailPack' ?
           <div className={`my-5 w-full flex justify-center items-center gap-3`}>
              <>
                  <button className='flex items-center justify-center  border border-[#707070] lg:w-[9rem] w-[8rem] whitespace-nowrap rounded-[5px] font-bold py-1.5 md:text-[13px] lg:text-[15px]' 
                  onClick={() => {
                      props.OnClose()
                      props.setCompleteNow(true);
                  }}>
                      Cancel
                  </button> 
                  <button className={`flex items-center justify-center text-white lg:w-[9rem] w-[8rem] whitespace-nowrap rounded-[5px] font-bold py-1.5 md:text-[13px] lg:text-[15px] ${changedFieldsCount == 0 ? 'bg-[#8E8E8E]' : 'bg-[#D10A11] border border-[#D10A11]'}`}
                    type="submit"
                    onClick={()=>{
                      onClickTrack(EventName.Trial_Pack_popup_Add_Your_Family_Details_Submit_Button);
                    } }
                  >
                      {submitStatus ? <><img src={LoaderGif} className="brightness-0 invert-[1] w-5 h-5" alt="" /><span>Saving...</span></> : (changedFieldsCount == 0 ? 'Save' : `Save (${changedFieldsCount})`) }
                  </button>
              </>
          </div> :
        <SaveCancelButton
          cancel={() => setEditView(false)}



          saveCallBack={(e) => {
            e.preventDefault();
            if (!formik.values.familyStatus || !formik.values.familyType || !formik.values.familyValues
              || !formik.values.parentsIsdCode
              || !formik.values.parentNumber || !formik.values.parentsMonthly || !formik.values.relationshipId
            ) {
              setCommonError("All fields are mandatory");
            } else {
              setCommonError("");
              formik.handleSubmit();
            }
          }}
          submitStatus={submitStatus}
          isShowSave={true}
           menu="astroadd"
           error={formik.errors}
           value={props?.data}
           inputvalue={formik.values}
           setOnchangeFamilyData={setOnchangeFamilyData}
           onchangefamilydata={onchangefamilydata}
        /> }
        
      </form>
    </div>
  );
};

export default FamilyAddComponent;
