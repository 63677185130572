import React, { useState } from 'react';
import groupicon from '../../../Components/Kalyanlite/assets/Images/Group 2017.png';
import addgroupicon from '../../../Components/Kalyanlite/assets/Images/addgroupicon.svg'
import groupimage from "../../../Components/Kalyanlite/assets/Images/Group 3177 (1).svg"
import Groupicon from '../../../Components/Kalyanlite/assets/Images/Group 2017.png';
import GroupImage from '../../../Components/Kalyanlite/assets/Images/Group 2269.svg';
import addmoreicon from '../../../Components/Kalyanlite/assets/Images/addmoreicon.svg';
import SuccessPopup from '../Popup/SuccessPopup.jsx';
import inviteicon from '../../../Components/Kalyanlite/assets/Images/inviteicon.svg';
import { CommonAddMorePopup, CommonErrorPopup, ReInviteModal, RemovePopup } from '../../CommonPopup.jsx';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useSelector, useDispatch } from "react-redux";
import * as KalyanLiteSelector from "../../../Store/KalyanLite/selectors";
import familyGroupicon from '../../../Components/Kalyanlite/assets/Images/familygroup.svg'
import CloseIconRed from '../../../Components/Kalyanlite/assets/Images/cancelicon.svg'
import * as Images from "./../../../Components/Kalyanlite/assets/images"
import { validateNumber } from '../../../Utils.js';
import avatar from '../../../Components/Kalyanlite/assets/Images/default_avatar.svg'
import { getIsLoginFromAdmin } from "../../../Utils";

const groupData = [
  // Add your group data objects here
  { id: 1, name: 'Family', image: groupicon, icon: addgroupicon },

  // Add more items as needed
];

const groupDatas = [
  // Add your group data objects here
  { id: 1, name: '+91 97634 56789', description: 'Relative', image: groupicon },
  { id: 2, name: 'Konstantin Frank', description: 'Relative', image: groupicon },

  // Add more items as needed
];


const MemberItem = ({apiCallFamilyData,setIsLoading,isParent,isParentsNumber,parentsNumber,relationshipid, avatar, title, subtitle, showClear, onClearClick ,showNumber ,userData ,familydata ,reinvitebutton}) => {
console.log(reinvitebutton,"familydata?.reinvitebutton");
  const [showModal, setShowModal] = useState(false);
  const [showRemoveModal, setShowRemoveModal] = useState(false);

const handleReInviteClick = () => {
  setShowModal(true);
};

const handleCloseModal = () => {
  setShowModal(false);
};
const onRemoveClick =()=>{
  setShowRemoveModal(true)
}


const handleSmsShare = () => {
  const message = encodeURIComponent(`Let’s find My Perfect Match together on Kalyan Matrimony. It's a simple, secure matrimony app. We can share, chat and discuss about profiles as a family together for free. 

Just Click the link below and download the App: https://play.google.com/store/apps/details?id=com.kalyanmatrimony.app

Let’s search and find matches together`);
  window.location.href = `sms:?body=${message}`;
  handleCloseModal();
};

  return <><div className={`${showClear ? "pl-[1.2rem] " : "px-[4.5rem]"} py-2 px-8 md:px-2 md:p-2 lg:px-3 justify-center flex xl:p-2 lg:h-[4rem] md:h-[4rem] h-[5rem] xl:h-[4rem] overflow-hidden hover:bg-[#E3E3E3] ${showNumber?"hover:bg-white":""} cursor-pointer`}>
    <div className={`${showClear ? "bg-[#E2E8F0] px-3" : ""} ${showNumber?"pl-[3.3rem] bg-white":""} w-full  lg:h-[4rem] xl:h-[4rem] flex `}>
      <div className={`${showClear ? "w-[18%]" :reinvitebutton=="yes" || userData?.isParentsNumber==false && userData?.useshowremovebutton == "no"?"w-[50%]": "w-[23%]"} h-full flex items-center xl:w-[17%] 2xl:w-[12%] 3xl:w-[10%] lg:w-[23%]`}>
        <img alt='' className={`${showNumber?"rounded-full lg:h-[35px] md:h-[35px] object-cover w-[35px] h-[35px] md:w-[35px]":"rounded-full lg:h-[44px] md:h-[38px] object-cover w-[44px] h-[44px] md:w-[46px]"} `}src={avatar} />

      </div>
      <div className={`${ showNumber?" md:pl-0":"md:pl-2"} w-[58%] md:w-[60%] pl-2 md:pl-0 lg:pl-0 h-full grow flex flex-col justify-center`}>
        <h3 className={`${ showNumber?"font-Poppins text-[#4C5B71] md:text-[13px] xl:text-[13px] lg:text-[13px] flex gap-2 items-center":"font-Poppins text-[#4C5B71] md:text-[13px] xl:text-[16px] lg:text-[13px] flex gap-2 items-center"}`}>
          {title}
        </h3>
        <p className="font-Poppins-Regular xl:w-[16rem] 2xl:w-[21rem] lg:w-[11rem] md:w-[13rem] w-[16rem] text-[#8292A8] lg:ml-0 text-[12px] md:text-[11px] xl:text-[13px] lg:text-[10px] overflow-hidden whitespace-nowrap overflow-ellipsis">
          {subtitle}
        </p>
      </div>
      {showClear && <div className=" right-0 flex h-full items-center justify-center mr-3">
        <img onClick={onClearClick} alt="" src={CloseIconRed} className="w-5 h-5" />
      </div>}
     {subtitle=="Parent" || subtitle =="Myself"?"": <div className='pt-1'>
        {isParentsNumber==true || userData?.useshowremovebutton == "no"?
        "":
               <button onClick={onRemoveClick} className="text-[10px]  underline font-Poppins text-[#4C5B71] whitespace-nowrap">Remove</button>
                }

        {reinvitebutton=="yes"? <button onClick={handleReInviteClick}  className="text-[10px] whitespace-nowrap underline font-Poppins text-[#D10A11]">Re-Invite</button>:""}
      </div>
        }
    </div>
    {showRemoveModal && <RemovePopup userData={userData} apiCallFamilyData={apiCallFamilyData} setIsLoading={setIsLoading} parentsNumber={parentsNumber} relationshipid={relationshipid} OnClose={()=>setShowRemoveModal(false)} title={"Remove Family Member"}/>}

    {showModal && <ReInviteModal  parentsNumber={parentsNumber} userData={userData} onClose={handleCloseModal} handleSmsShare={handleSmsShare}  />}
 </div>
      {isParent && 
  <div className="relative left-[4rem]">
      <p className="text-[12px] text-[#D10A11]" style={{textWrap:"balance"}}>*This number will be visible to Matches. Matches can also contact you on this number</p>
  </div>
 }
 </>


}




const MatchGroupList = ({
  onItemClick,
  formik,
  addMoreData,
  setAddMoreData,
  familydata,
  removeNumber,
  showdata,
  setShowData,
  isUserNumber,
  setIsUserNumber,
  duplicateNumCheck,
  userData,
  setIsLoading,
  apiCallFamilyData
}) => {



  const onListItemClick = (group) => {
    setShowData((prevShowData) => !prevShowData);

  };

  const [addmore, setAddmore] = useState(false);
  const [addmoreError, setAddmoreError] = useState(false);
  const [hideButton, setHideButton] = useState(false);



  const isParents = familydata?.filter(data => data?.relationshipName?.includes("Parent"))?.length > 0
  const isReachedMax = (familydata.length + addMoreData?.length + (isParents ? 0 : 1) + (addmore ? 1 : 0)) > 5;
 
console.log(familydata,'familydata12');
  const onAddmoreClick = async () => {
    if (isReachedMax) {
      setAddmoreError(true);
      return;
    }

    if(formik?.values?.parentCheck == true){
      setHideButton(false);
    }

    if (formik?.values?.parentsMobileNumber && formik?.values?.relationshipId) {

      setAddMoreData(prev => ([...prev, {
        parentsMobileNumber: formik?.values?.parentsMobileNumber,
        parentsIsdcode: formik?.values?.parentsIsdcode,
        relationshipId: formik?.values?.relationshipId,
      }]));
      formik.resetForm();
    } else {
      if (addmore) {
        formik.validateForm()
      }
    }

    setAddmore(true);


  };


  const userProfileData = useSelector(
    (state) => state?.DashBoardAPI?.ProfileData?.data,
  );
  const { dropdownData } = useSelector((state) => ({
    dropdownData: state?.Home?.rData?.data,
  }));


  console.log(userProfileData, "userProfileData");

  const isParentNumberAvailable = userProfileData?.parentsNumberAvailable == "No";

  const isAvailable = familydata?.filter(data => data?.parentsMobileNumber?.includes(userProfileData?.parentsMobileNumber))?.length > 0;


  return (
    <>
      {addmoreError && (
        <CommonErrorPopup closePopup={() => setAddmoreError(false)} />
      )}
      <div className='hidden md:block bg-white h-full '>

        {/* <div className="border border-b-[#DBDBDB] w-full px-4 h-20 lg:h-[4rem] xl:h-20 flex ">
          <div className=" w-[15%] h-full flex items-center xl:w-[15%] 2xl:w-[12%] 3xl:w-[10%] lg:w-[20%]">
            <img
              className="lg:h-[40px] md:h-[34px] bg-[] object-cover w-[44px] h-[43px] md:w-[39px]"
              src={groupimage}

            />
          </div>
          <div className="w-[60%]  h-full grow flex flex-col justify-center">
            <h3 className="font-Poppins md:pl-2 lg:pl-0 text-[#4C5B71] md:text-[13px] xl:text-[16px] lg:text-[13px] flex gap-2 items-center">
              New  Group
            </h3>

          </div>
        </div> */}


        {groupData.map((group) => (
          <div onClick={() => onItemClick(group)}
            key={group.id}
            className="overflow-hidden cursor-pointer"
          >

            <div className="w-full px-3 hover:bg-[#E6F1F5] md:h-[4rem] h-[3rem] lg:mt-[1rem]  lg:h-[4rem] xl:h-[4rem] flex ">
              <div onClick={onListItemClick} className='w-[17%] h-full flex items-center xl:w-[17%] 2xl:w-[12%] 3xl:w-[10%] lg:w-[21%]'>
                <img alt='' className="rounded-full lg:h-[46px] lg:w-[46px] md:h-[40px] object-cover w-[44px] h-[43px] md:w-[46px]"
                  src={userProfileData?.avatar === "N" ? userProfileData?.profile_imageurl :
                    userProfileData?.avatar === "Y" ? familyGroupicon : familyGroupicon
                  } />

                <img className="lg:w-[1rem]  lg:h-[1rem] lg:mt-[2rem] w-5 h-5 mt-[2.4rem]  ml-[-1.2rem]" src={group.icon} alt={`${group.name} icon`} />

              </div>
              <div className="w-[60%] md:pl-2 lg:pl-1 h-full grow flex flex-col justify-center">
                <h3 className="font-Poppins text-[#4C5B71] md:text-[13px] xl:text-[16px] lg:text-[13px] flex gap-2 items-center">
                  {group.name}
                </h3>
                <p className="font-Poppins-Regular xl:w-[16rem] 2xl:w-[21rem] lg:w-[11rem] md:w-[13rem] w-[16rem] text-[#8292A8] lg:ml-0 text-[12px] md:text-[11px] xl:text-[13px] lg:text-[10px] overflow-hidden whitespace-nowrap overflow-ellipsis">
                  {group.description}
                </p>
              </div>
            </div>

          </div>
        ))}

        {showdata &&(
          <>
          {userProfileData?.userphoneno ?
        <MemberItem
        showNumber={true}
          subtitle={"Myself"}
          title={ userProfileData?.userphoneno}
          avatar={userProfileData?.avatar === "N" ? userProfileData?.profile_imageurl :
            userProfileData?.avatar === "Y" ? Images.DefaultAvatar : Images.DefaultAvatar}
            userData={userData}
            familydata={familydata}
        />:""}
        
        { !isAvailable && userProfileData?.parentsMobileNumber ?
          <MemberItem
          showNumber={true}
            subtitle={"Parent"}
            title={userProfileData?.parentsMobileNumber}
            avatar={avatar}
            userData={userData}
            familydata={familydata}
          /> : ""}
          </>
        )
          }
        
         
      </div>

      <div className='md:hidden bg-white h-full'>
        {/* <div className="border border-b-[#DBDBDB] w-full px-4 h-20 lg:h-[4rem] xl:h-20 flex ">
          <div className="w-[15%] h-full flex items-center xl:w-[15%] 2xl:w-[12%] 3xl:w-[10%] lg:w-[20%]">
            <img
              className="lg:h-[40px] md:h-[17px] bg-[] object-cover w-[44px] h-[43px] md:w-[39px]"
              src={groupimage}
            />
          </div>
          <div className="w-[60%]  h-full grow flex flex-col justify-center">
            <h3 className="font-Poppins text-[#4C5B71] md:text-[13px] xl:text-[16px] lg:text-[13px] flex gap-2 items-center">
              New Group
            </h3>
          </div>
        </div> */}
        {getIsLoginFromAdmin() && userProfileData?.viewfamilycode != "" ?
        <div className='flex items-center justify-end px-5 py-3'>
            <p className='text-[#D10A11] text-[14px] font-semibold'>Family Code : xxxx</p>
        </div>
        :
        userProfileData?.viewfamilycode != "" ?
        <div className='flex items-center justify-end px-5 py-3'>
            <p className='text-[#D10A11] text-[14px] font-semibold'>Family Code : {userProfileData?.viewfamilycode}</p>
        </div>
        : 
        <></>
        }
        <div className="py-2  px-4  overflow-hidden cursor-pointer">
          <div className="w-full px-3 hover:bg-[#E6F1F5]  h-20  lg:h-[4rem] xl:h-[3rem] flex ">
            <div onClick={onAddmoreClick} className='w-[17%] h-full flex items-center xl:w-[17%] 2xl:w-[12%] 3xl:w-[10%] lg:w-[23%]'>
              <img className="rounded-full lg:h-[40px] lg:w-[41px] xl:w-[47px] xl:h-[49px] md:h-[38px] object-cover w-[50px] h-[50px] md:w-[46px]" src={familyGroupicon} alt=''/>

              <img className="lg:w-[1rem]  lg:h-[1rem] lg:mt-[2rem] w-5 h-5 mt-[2rem]  ml-[-1.4rem]" src={addgroupicon} alt=''/>

            </div>
            <div onClick={onListItemClick} className="w-[60%] pl-2 lg:pl-0 h-full grow flex flex-col justify-center">
              <h3 className="font-Poppins text-[#4C5B71] md:text-[13px] xl:text-[16px] lg:text-[13px] flex gap-2 items-center">
                Family
              </h3>
              {/* <p className="font-Poppins-Regular xl:w-[16rem] 2xl:w-[21rem] lg:w-[11rem] md:w-[13rem] w-[16rem] text-[#8292A8] lg:ml-0 text-[12px] md:text-[11px] xl:text-[13px] lg:text-[10px] overflow-hidden whitespace-nowrap overflow-ellipsis">
                Lorem ipsum dolor sit amet
              </p> */}
            </div>
          </div>


        </div>

        {showdata && (
          <>
           {userProfileData?.userphoneno ?
        <MemberItem
       
          subtitle={"Myself"}
          title={userProfileData?.userphoneno}
          avatar={userProfileData?.avatar === "N" ? userProfileData?.profile_imageurl :
            userProfileData?.avatar === "Y" ? Images.DefaultAvatar : Images.DefaultAvatar}

        />:""}
            {!isAvailable && userProfileData?.parentsMobileNumber ?
              <MemberItem
                subtitle={"Parent"}
                title={userProfileData?.parentsMobileNumber}
                avatar={GroupImage}
              /> : ""}

                    {familydata?.length > 0 &&
                      (
                        familydata.map((member, index) => (
                           <MemberItem
                            subtitle={ member?.relationshipName}
                            title={member?.parentsMobileNumber}
                            avatar={GroupImage}
                            reinvitebutton={member?.reinvitebutton}
                            parentsNumber={member?.parentsMobileNumber}
                           relationshipid={member?.relationshipId}
                           isParentsNumber={member?.isParentsNumber}
                           isParent={member?.isParentsNumber}
                           setIsLoading={setIsLoading}
                           apiCallFamilyData={apiCallFamilyData}
                           userData={userData}
                          />
                        ))
                      )
                    }

              
              { console.log(familydata?.relationshipName,"member?.relationshipName")}

            {
              addMoreData?.map(data => {
                return <MemberItem
                  subtitle={
                    dropdownData?.relationShip?.find(
                      (relation) => relation.id === data?.relationshipId
                    )?.name}
                  title={data?.parentsMobileNumber}
                  avatar={GroupImage}
                  showClear={true}
                  isParent={data?.isParentsNumber}
                  onClearClick={() => removeNumber(data?.parentsMobileNumber)}
                />
              })
            }
            {(familydata?.length < 6) && (
              <div>
                <p className='font-Poppins-SemiBold text-[#1A1A1A] md:text-[13px] xl:text-[16px] lg:text-[12px] text-left pl-8 pt-12'>Add Family Members :</p>
                <form onSubmit={formik.handleSubmit} className="flex flex-col my-3 justify-center items-center xl:pl-[3rem] lg:pl-0">
                  <div className="flex space-x-2 w-[20rem] ">
                    <label className="selected select font-bol outline-none d text-[#575556] w-[5rem] md:w-[4rem] lg:w-[4.3rem]">
                      <select
                        id="parentsIsdcode"
                        name="parentsIsdcode"
                        value={formik.values.parentsIsdcode}
                        onChange={formik.handleChange}
                        className="selected border border-[#C4C4C4] form-input shadow-sm pt-[0.4rem] bg-[#E9E9E9]  text-[#495057] text-[13px] md:text-[13px] focus:outline-none rounded-[8px] focus:ring-[#2D2C6F]  py-[8px] px-2   md:py-2 md:px-2  w-full block !bg-[position:calc(100%_-_5px)_8px] md:!bg-[position:calc(100%_-_5px)_14px] lg:!bg-[position:calc(100%_-_5px)_14px]"
                      >
                        {/* <option value="" disabled selected>
                    Select
                  </option> */}

                        {dropdownData?.isdcode?.map((isdcode, i) => (
                          <option className="p-2" value={isdcode.countryId}>
                            {isdcode.isdcode}
                          </option>
                        ))}

                      </select>

                    </label>

                    <label className="">
                      <input
                        name='parentsMobileNumber'
                        id='parentsMobileNumber'
                        type='tel'
                        placeholder="Enter Phone Number"
                        value={formik.values.parentsMobileNumber}
                        onChange={(e) => {
                          if (validateNumber(e.target.value)) {
                            duplicateNumCheck(e.target.value)
                            formik.handleChange(e)
                          }
                        }}
                        className="no-spinner bg-white outline-none p-1.5 my-1 w-full h-[2.3rem] text-[13px] font-Poppins text-[#575556] rounded-[3px] border border-[#C4C4C4]"
                      />
                      <div className="h-2 mb-2">
                        {formik.errors.parentsMobileNumber && (
                          <p className="text-[red] text-[12px]">{formik.errors.parentsMobileNumber}</p>
                        )}
                        {isUserNumber && (
                          <p className="text-[red] text-[12px]">{"Profile number cannot be added"}</p>
                        )}
                      </div>
                    </label>
                  </div>
                  <div className=" space-x-2 my-2 w-[20rem]">
                    <label className="selected select text-[#575556] w-full">
                      <select
                        id="relationshipId"
                        name="relationshipId"
                        value={formik.values.relationshipId}
                        onChange={(e)=>{
                          if(isParentNumberAvailable){
                          if(e.target.value == "5" || e.target.value == "6"){
                            if(hideButton){
                              formik.setFieldValue("parentCheck", false);
                            }else{
                              formik.setFieldValue("parentCheck", true);
                              setHideButton(true);
                            }
                          }else{
                            formik.setFieldValue("parentCheck", false);
                            setHideButton(false);
                          }
                        }
                          formik.handleChange(e)
                        }}
                        className=" selected form-input shadow-sm bg-[#F0F2F5] text-[#495057] text-[13px] md:text-[13px]  focus:outline-none rounded-[8px] focus:ring-[#2D2C6F] py-[8px] px-1 md:py-2 md:px-2  w-full block "
                      >
                        <option className="p-2" value="" disabled selected hidden>
                          select relation
                        </option>
                        {dropdownData?.relationShip?.map((profile, i) => (
                          <option className="p-2" value={profile.id}>
                            {profile.name}
                          </option>
                        ))}
                      </select>

                      {formik.errors.relationshipId && (
                        <p className="text-[red] text-[12px]">{formik.errors.relationshipId}</p>
                      )}
                    </label>
                  </div>
                  {isParentNumberAvailable && hideButton &&
                    <div className='flex items-center gap-2 w-[20rem] whitespace-nowrap pt-4'>
                        <input
                              type="checkbox"
                              name="parentCheck"
                              checked={formik.values.parentCheck ?? false}
                              onChange={formik.handleChange}
                              className="form-radio h-4 w-4 accent-[#D10A11] !border !border-gray-400 relative cursor-pointer"
                            />
                        <p className="text-gray-600 text-[12px] md:text-[12px] lg:text-[12px]" >
                          Add this number to my profile as Parent's Number
                        </p>
                    </div>
                  }
                  <div className="flex justify-center mt-5 items-center space-x-8 ">
                    <button
                      type="submit"
                      disabled={formik.isSubmitting}
                      className="flex items-center cursor-pointer gap-2 justify-center my-1 h-[1.5rem] text-white bg-[#D10A11] border border-[#D10A11] px-[2.3rem] md:px-[2.3rem] lg:px-[2.8rem] whitespace-nowrap rounded-[5px] font-segoe font-semibold py-[1rem] md:py-[1rem] lg:py-4 md:text-[12px] lg:text-[12px]"
                    >
                      <span>{formik.isSubmitting ? "Sending..." : "Send Invite"}</span> <img alt="" className="w-4 h-4" src={inviteicon} />
                    </button>
                  </div>
                </form>
              </div>
            )}

            {familydata?.length < 6 && <div className=" xl:pl-[3rem] pl-[16rem] md:pl-[4rem]">
              <button
                disabled={formik.isSubmitting}
                onClick={onAddmoreClick}
                className="flex gap-2 justify-center  py-5 cursor-pointer "
              >
                <img alt="" className="w-4 h-4" src={addmoreicon} />
                <p className="text-[13px] font-bold text-[#575556]">Add More</p>
              </button>
            </div>}
          </>
        )}
      </div>
    </>

  );
}

export default MatchGroupList;
