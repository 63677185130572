import React from 'react'


function ProcessImage({
    className,
    lockClassName = "h-[18px]",
    alt,
    src,
    alreadyContacted,
    myphoto,
    profileStatus,
    blurPhoto,
    passwordProtected,
    onClick,
    isAvatarPic,
    containerClassName = "",
    children,
    ...props
}) {


    const blurImage =  isAvatarPic ? "N" : blurPhoto
    const showLock = isAvatarPic ? "N" : passwordProtected


    return (
        <div onClick={onClick} className={`relative ${containerClassName}`}>
            <img className={`${className} ${blurImage == "Y" ? "blur-[4px]" : ""}`} alt={alt} src={src} {...props} />
            {showLock === "Y" ? <img
                className={`absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 ${lockClassName}`}
                src={"/Assets/Images/lockicon.svg"}
                alt="Person"
            /> : null}
            {children}
        </div>
    )
}

export default ProcessImage