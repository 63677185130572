import React, { useState } from 'react';
import 'react-loading-skeleton/dist/skeleton.css'
import onlineicon from './../assets/Images/online icon.png'
import * as Images from "../assets/images"
import { Modal } from 'antd';
import MatchPopup from './MatchPopup';
import ProcessImage from '../Common/ProcessImage';
import { CommonPopup } from '../../CommonPopup';

export default function MatchItem({ data,welcomPage, pp,profileStatus,setshowWelcomPage, contact, contactInfo, time, active, work, onClick, searchTerm, adminSelected, profileId }) {


  const [isModalOpen, setIsModalOpen] = useState(false);

  const [showProfileHiddenPopup, setShowProfileHiddenPopup] = useState(false);
  const profileDetails = data;
 console.log(welcomPage,"welcomepageb");
  function getHighlightedText(text, highlight) {
    // Split text on highlight term, include term itself into parts, ignore case
    const parts = text.split(new RegExp(`(${highlight})`, 'gi'));
    return <span>{parts.map(part => part.toLowerCase() === highlight.toLowerCase() ? <span className="font-bold">{part}</span> : part)}</span>;
  }


  const profilePic = pp?.includes("femaleavatar") || pp?.includes("maleavatar") ? Images?.DefaultAvatar : pp


  const handleImageClick = (e) => {
    e.stopPropagation()
    setIsModalOpen(true);
  };

  const handleClick = () => {
  
    if (profileStatus === "hidden" || profileStatus==="inactive"||
     profileStatus==="suspension"
    ||profileStatus==="delete") {
      // welcomPage(true);
      // setshowWelcomPage(true)
      setShowProfileHiddenPopup(true);
     
    } else {
      onClick();
      // 
      let storedProfileIds = JSON.parse(sessionStorage.getItem('viewedProfileIds')) || [];

      // Check if the clicked profile ID is already stored
      if (!storedProfileIds.includes(profileId)) {
        // Add the new profile ID to the list
        storedProfileIds.push(profileId);
  
        // Update local storage with the new array
        sessionStorage.setItem('viewedProfileIds', JSON.stringify(storedProfileIds));
      }
    }
  };


  const highlightSearchTerm = () => {
    if (!searchTerm) {
      return contact;
    }

    return getHighlightedText(contact, searchTerm)

    // const regex = new RegExp(`(${searchTerm})`, 'gi');
    // return contact.split(regex).map((part, index) =>
    //   regex.test(part) ? <span key={index} className="font-bold">{part}</span> : part
    // );
  };

  const formatLastSeen = (lastSeen) => {
    const now = new Date();
    const lastSeenDate = new Date(lastSeen);

    // Check if the user was seen today
    if (now.toDateString() === lastSeenDate.toDateString()) {
      // Format time as HH:mm AM/PM
      return lastSeenDate.toLocaleTimeString([], { hour: 'numeric', hourCycle: "h12", minute: '2-digit' });
    }

    // Check if the user was seen yesterday
    const yesterday = new Date(now);
    yesterday.setDate(now.getDate() - 1);
    if (yesterday.toDateString() === lastSeenDate.toDateString()) {
      return 'Yesterday';
    }

    // Format as DD/MM/YY
    const day = lastSeenDate.getDate();
    const month = lastSeenDate.getMonth() + 1;
    const year = lastSeenDate.getFullYear() % 100;
    return `${day}/${month}/${year}`;
  };
  // const viewPhoneData = profileData?.viewPhone;



  const handleError = (event) => {
    // Handle the image loading error
    event.target.src = Images.DefaultAvatar; // Set the source to the default image
  };


  return (
    <>
      <Modal
        closable={false}
        footer={null}
        className='match-popup'
        onCancel={() => setIsModalOpen(false)} open={isModalOpen} centered>
        <MatchPopup profileId={profileId} close={() => setIsModalOpen(false)} onViewProfile={onClick} />
      </Modal >

      {showProfileHiddenPopup && (
  <CommonPopup
    menu="liteprofile"
    title="Profile Status"
    close={() => setShowProfileHiddenPopup(false)}
  >
    <p>{profileStatus === "hidden" ||profileStatus==="inactive"
            ? `Profile is ${profileStatus}` : profileStatus === "delete" ? "Profile is deleted" :
              profileStatus === "suspension" ? "Profile is suspended" : `Your Profile is ${profileStatus}`}</p>
  </CommonPopup>
)}



      <div onClick={() => handleClick()} id={`match-item-${profileId}`} className={`px-4 py-1  md:p-2 lg:px-3 xl:p-2 lg:h-[4.6rem] h-[5.3rem] xl:h-[5rem] ${contact === "kalyan matrimony" ? "px-4 py-1  md:p-2 lg:px-3 xl:p-2 lg:h-[4.6rem] md:mb-[-1rem] lg:mb-0 h-[5.3rem] xl:h-[5rem]" : ""}  ${adminSelected || data?.FamilySelect ? "" : "overflow-hidden"} hover:bg-[#E3E3E3] cursor-pointer flex items-center  ${active ? "bg-gray-200" : "bg-[#F7F8FA]"}`}>
        <div className="w-full h-20 lg:h-[4rem] xl:h-[4.5rem] flex ">
          <div className='w-[21%] h-full relative flex items-center xl:w-[19%] 2xl:w-[15%] 3xl:w-[13%] lg:w-[20%] md:w-[17%]'>
            {data?.FamilySelect ?
              // <div className="flex justify-center items-center">
              //   <div className="relative">
              //     <div className="absolute w-[2rem] top-[-2.5rem] md:top-[-2rem] lg:top-[-2.2rem] xl:top-[-2.4rem] left-[1.4rem] md:left-[1.2rem] lg:left-[1rem] xl:left-[1.4rem] z-50 border-l-[15px] border-l-transparent border-t-[17px] border-t-[#FDC300] border-r-[22px] border-r-transparent">
              //     </div>
              //     <p className="text-[#000000] text-[8px] absolute top-[-2.9rem] md:top-[-2.3rem] lg:top-[-2.4rem] xl:top-[-2.6rem] left-[1.4rem] md:left-[1.1rem] lg:left-[1rem] xl:left-[1.4rem] z-50 font-segeo rounded-lg font-bold bg-[#FDC300] px-[8px] pt-2 md:pt-[4.2px] whitespace-nowrap">
              //       {data?.FamilySelect}
              //     </p>
              //   </div>
              // </div>
              <div className="flex justify-center items-center">
                <div className="relative">
                  <div className="absolute w-[2rem] top-[-2.5rem] left-[1rem] z-[1] border-l-[15px] border-l-transparent border-t-[17px] border-t-[#FDC300] border-r-[22px] border-r-transparent">
                  </div>
                  <p className="text-black text-[8px] absolute top-[-2.9rem] left-[1rem] z-[1] font-segeo rounded-lg font-bold bg-[#FDC300] px-[8px] py-[4px] whitespace-nowrap">
                  {data?.FamilySelect}
                  </p>
                </div>
              </div>
              :
              adminSelected ?
              <div className="flex justify-center items-center">
                <div className="relative">
                  <div className="absolute w-[2rem] top-[-2.5rem] left-[1rem] z-[1] border-l-[15px] border-l-transparent border-t-[17px] border-t-[#D10A11] border-r-[22px] border-r-transparent">
                  </div>
                  <p className="text-white text-[8px] absolute top-[-2.9rem] left-[1rem] z-[1] font-segeo rounded-lg font-bold bg-[#D10A11] px-[8px] py-[4px] whitespace-nowrap">
                    Profile selected by your Relationship Executive
                  </p>
                </div>
              </div>
              :
              <></>
            }

            <ProcessImage
              blurPhoto={data?.profileblurPhoto}
              alreadyContacted={data?.profilealreadyContected}
              passwordProtected={data?.profilefromPasswordProtected}
              onClick={null}
              onError={handleError}
              isAvatarPic={data?.isAvatarPic}
              className={`hidden md:block rounded-full lg:h-[43px] lg:w-[43px] xl:h-[52px] xl:w-[52px] md:h-[43px] object-cover w-[60px] h-[58px] md:w-[43px]`}
              src={profilePic}
              alt=""
            />

            <ProcessImage
              blurPhoto={data?.profileblurPhoto}
              alreadyContacted={data?.profilealreadyContected}
              passwordProtected={data?.profilefromPasswordProtected}
              onClick={handleImageClick}
              onError={handleError}
              isAvatarPic={data?.isAvatarPic}
              className={`md:hidden rounded-full lg:h-[43px] lg:w-[43px] xl:h-[52px] xl:w-[52px] md:h-[43px] object-cover w-[60px] h-[58px] md:w-[43px]`}
              src={profilePic}
              alt=""
            />


                          
            {/* <img
              onError={handleError}
              className={`${!profileDetails?.profiledetails?.isAvatarPic?
              profileDetails?.blurPhoto == "Y" ? 
            profileDetails?.alreadyContected == "Y" ?
            "a" 
            :profileDetails?.alreadyContected == "N"  ? 
            "blur-[2px]" 
            :"blur-[2px]" 
            : profileDetails?.alreadyContected == "N" 
            && profileDetails?.myphoto == "" || profileDetails?.myphoto == "N"
            
            ? "b"
          
            : profileDetails?.alreadyContected == "N" 
            && profileDetails?.myphoto == "Y" 
            
            ?"c"
            :
            profileDetails?.myphoto == "Y" && 
            profileDetails?.status == "open" ? // view all photo
            "d"
            :profileDetails?.myphoto == "" || profileDetails?.myphoto == "N"?" "                                   
            :"e"
            :"f"
            
              } md:hidden rounded-full lg:h-[43px] lg:w-[43px] xl:h-[52px] xl:w-[52px] md:h-[43px] object-cover w-[60px] h-[58px] md:w-[43px]`} src={profilePic} alt={contact} onClick={handleImageClick} /> */}

          </div>
          <div className="w-[60%] md:pl-2 lg:pl-0 h-full grow flex flex-col justify-center" >
            <h3 className="font-Poppins text-[#4C5B71] md:text-[13px] xl:text-[16px] lg:text-[13px] flex gap-2 items-center">{highlightSearchTerm()}
              {/* {contact == "Senthil" && 
                        <span>
                            <img className="w-4 h-4" src={VerifiedIcon} alt="Verified" /></span>
                            } */}
            </h3>
            <p className="font-Poppins-Regular xl:w-[16rem] 2xl:w-[21rem] lg:w-[11rem] md:w-[13rem] w-[16rem] text-[#8292A8] lg:ml-0 text-[12px] md:text-[11px] xl:text-[13px] lg:text-[10px] overflow-hidden whitespace-nowrap overflow-ellipsis">{contactInfo}</p>
            <p className="font-Poppins-Regular xl:w-[16rem] 2xl:w-[21rem] lg:w-[11rem] md:w-[13rem] w-[16rem] text-[#8292A8] lg:ml-0 text-[12px] md:text-[11px] xl:text-[13px] lg:text-[10px] overflow-hidden whitespace-nowrap overflow-ellipsis">{work}</p>
          </div>
          <div className="w-[15%] h-full rounded-full self-start flex justify-end items-start">
            {profileStatus === "inactive" ? 
              <p className="font-Poppins text-[#8292A8] text-[9px] lg:text-[8px] xl:text-[9px] lg:pt-3 xl:pt-3 pt-4">Inactive</p>
              :
              time && time === "Online Now" ?
                <>
                  <p className="font-Poppins text-[#039511] lg:text-[8px] text-[9px] xl:text-[9px] lg:pt-3 xl:pt-4 flex pt-5">
                    <img className='w-3 h-3' src={onlineicon} alt='' />
                    <span className='pl-1 flex justify-center items-center'>Online</span>
                  </p>
                </>
              :
              time ? <p className="font-Poppins text-[#8292A8] text-[9px] lg:text-[8px] xl:text-[9px] lg:pt-3 xl:pt-3 pt-4">{formatLastSeen(time)}</p> : null
            }
          </div>

        </div>

      </div >
    </>
  )
}




export function SkeletonMatchItem() {
  return (
    <div className="w-full p-4 md:p-2 lg:p-2 rounded-md">
      <div className="flex animate-pulse flex-row items-center h-full">
        <div className="bg-gray-300 w-[3.5rem] h-[3.5rem] md:w-8 md:h-8 lg:w-10 lg:h-10 rounded-full mr-4 md:mr-2 lg:mr-4 ">
        </div>
        <div className="flex flex-col space-y-2 grow">
          <div className="w-[30%] bg-gray-300 h-3 rounded-md ">
          </div>
          <div className="w-full bg-gray-300 h-3 rounded-md ">
          </div>
          <div className="w-full bg-gray-300 h-3 rounded-md ">
          </div>
        </div>
      </div>
    </div>
  )

}

export function SenderChatItemSkeleton() {
  return (
    <div className='w-1/2 p-4 md:p-0 md:px-1 lg:px-1 rounded-md'>
      <div className='flex animate-pulse gap-4 flex-row items-start h-full'>
     
        <div className='w-full flex flex-col'>
          <div className='box sb1 w-4 relative top-1 bg-gray-300 rounded-bl-md self-start'></div>

          <div className='w-full bg-gray-300 h-[2rem] rounded-md'></div>
          
        </div>
        <div className="bg-gray-300 w-[3.5rem] h-[3.5rem] md:w-8 md:h-8 lg:w-6 lg:h-6 rounded-full mr-4 md:mr-2 lg:mr-0 ">
        </div>
        
      </div>
    </div>
  );
}

export function ReceiverChatItemSkeleton() {
  return (
    <div className='w-1/2 p-4 md:p-0 md:px-1 lg:px-1 rounded-md'>
      <div className='flex animate-pulse flex-row items-start h-full'>
      <div className="bg-gray-300 w-[3.5rem] h-[3.5rem] md:w-8 md:h-8 lg:w-10 lg:h-10 rounded-full mr-4 md:mr-2 lg:mr-4 ">
        </div>
        <div className='w-full flex flex-col space-y-3'>
        <div className='box sb2 w-4 h-4 relative top-4 bg-gray-300 rounded-bl-md self-start'></div>
          <div className='w-full bg-gray-300 h-[5rem] rounded-md'></div>

        </div>
      </div>
    </div>
  );
}